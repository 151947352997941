import { FunctionComponent, useState } from "react";
import useKeyboard from "../../hook/keyboard";
//import { windows, categories } from "./Windows";
import { Layout } from "../../components/layout/DefaultLayout";

import TutoSection from "./TutoSection";
import TUTOS from "./TutoComponents";
import "./Tuto.scss";

function romanize (num) {
	if (isNaN(num))
		return NaN;
	var digits = String(+num).split(""),
		key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
			"","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
			"","I","II","III","IV","V","VI","VII","VIII","IX"],
		roman = "",
		i = 3;
	while (i--)
		roman = (key[+digits.pop() + (i * 10)] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
}



const Tuto: FunctionComponent = () => {
	const [debug, setDebug] = useState(false);
	const [showNewWindow, setShowNewWindow] = useState(false);
	useKeyboard([
		{ action: "showDebug", cb: setDebug },
		{ action: "showNewWindow", cb: setShowNewWindow }
	]);
	return (
		<Layout>
			<div style={{
				height: "100%",
					display:"flex",
					overflow: "scroll",
					flexDirection: "row",
				}}>

				<div style={{ width: "300px" }} >

					<h1>
						Sommaire
					</h1>

					{ TUTOS.map((t, i) =>
					<div style={{display: "flex", flexDirection: "row" }}>
						<div style={{width: "50px", marginRight:"10px", textAlign: "right", }}>
							{romanize(i + 1)}.
						</div>
						<div style={{}}>
							{t.title}
						</div>
					</div>
					)}

				</div>

				<div style={{
					width: "calc(100vw - 300px)",
						height: "100%",
						display:"flex",
						overflow: "scroll",
						alignItems: "center",
						flexDirection: "column"
					}}>


					<h1>Welcom to Maestro</h1>
					<p>
						This is a window manager that permeit you to build panels and load instruments
						Then you can link instruments together 
					</p>

					{ TUTOS.map((t, i) => 
					<TutoSection title={`${romanize(i + 1)}. ${t.title}`} usage={t.usage}>
						{t.content}
					</TutoSection>
					)}

				</div>
			</div>

		</Layout>
	);
};

export default Tuto;
