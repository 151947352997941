import React, { FunctionComponent, useState } from 'react'
import useKeyboard from "../../hook/keyboard";
import { AppState } from "../../store/types";
import { useSelector } from "react-redux";
import  InstrumentsLinkerOverseer from "../../components/instrumentLinker/instrumentLinkerOverseer"
import { InstrumentsLinker } from "../../components/instrumentLinker/InstrumentLinker";
import {KeyBind } from "../../instruments/Keyboard";
import windows from "../../instruments/index";
import {  Wm } from "@ledr/instruments";


const categories = [
	{
		id: "Category_A",
		name: "Explore",
		content: ["outliner", "map", "viewport", "windowManager"],
	},
	{
		id: "Category_C",
		name: "Developer",
		content: ["Printer", "logger", "stored", "EntityLinkerDebug", "Keyboard"],
	},
	{
		id: "Category_B",
		name: "Entity",
		content: ["EntityViewer", "EntityMetadata", "createEntity", "svgReader", "composer"],
	},
];

let panel = {
	id: "PanelEntityNid_A",
	name: "Window Manager",
	icon: "MdBorderAll",
	options: {
		debug: false,
		hideWinTabs: false,
		showNewWindow: false,
		twmResizeable: true,
		showPad: true,
	},
	categories: ["Category_A", "Category_B", "Category_C"],
	links: [],
	twmArbo: {
		direction: 0,
		ratio: 100,
		children: [
			{
				id: "NHU_ID_A_1",
				component: "windowManager",
				ratio: 100,
			}
		],
	},
	fwmWindows: [],
};

const Tuto1Usage: FunctionComponent = () => {
	return(
		<>
			Here is an example of an instrument wrapped in a window
			<br/>
			A Window is constitued with an upper control tab bar
			<br/>
			With left's menu button you can change instrument
			<br/>
			Right button used to somes options, or window closing.
		</>
	)
}

const Tuto1: FunctionComponent = () => {
	return(
		<InstrumentsLinkerOverseer links={panel.links} >
			<Wm
				tilingWindows={ {
					direction: 0,
						ratio: 100,
						children: [
							{
								id: "NHU_ID_A_1",
								component: "EntityViewer",
								ratio: 100,
							}
						],
					}
				}
				floatingWindows={[]}
				categories={categories}
				windows={windows}
				options={{ ...panel?.options, }}
				onChange={(twmArbo, floatingWindows) => { }}
				WrappingWindow={InstrumentsLinker}
			/>
		</InstrumentsLinkerOverseer>
	)
}


const Tuto2Usage: FunctionComponent = () => {

	const userKeyboard = useSelector((state: AppState) => state.devices.keyboard);
	return(
		<>
			You can build panel or dashboard with a collection of instruments
			{userKeyboard.map(key => <KeyBind keyBind={key} />)}

		</>
	)
}


const Tuto2: FunctionComponent = () => {

	const [debug, setDebug] = useState(false);
	const [showNewWindow, setShowNewWindow] = useState(false);
	useKeyboard([
		{ action: "showDebug", cb: setDebug },
		{ action: "showNewWindow", cb: setShowNewWindow }
	]);
	return(
		<InstrumentsLinkerOverseer links={panel.links} >
			<Wm
				tilingWindows={ {
					direction: 0,
						ratio: 100,
						children: [
							{
								id: "NHU_ID_A_1",
								component: "windowManager",
								ratio: 100,
							},
							{
								id: "NHU_ID_A_2",
								component: "logger",
								ratio: 100,
							}
						],
					}
				}
				floatingWindows={[]}
				categories={categories}
				windows={windows}
				options={{ ...panel?.options,
						debug,
						showNewWindow
				}}
				onChange={(twmArbo, floatingWindows) => { }}
				WrappingWindow={InstrumentsLinker}
			/>
		</InstrumentsLinkerOverseer>
	)
}


const Tuto3: FunctionComponent = () => {
	return(
		<InstrumentsLinkerOverseer links={panel.links} >
			<Wm
				tilingWindows={ {
					direction: 0,
						ratio: 100,
						children: [
							{
								id: "NHU_ID_A_1",
								component: "windowManager",
								ratio: 100,
							},
						],
					}
				}
				floatingWindows={[]}
				categories={categories}
				windows={windows}
				options={{ ...panel?.options, }}
				onChange={(twmArbo, floatingWindows) => { }}
				WrappingWindow={InstrumentsLinker}
			/>
		</InstrumentsLinkerOverseer>
	)
}

const Tuto4: FunctionComponent = () => {

	return(
		<InstrumentsLinkerOverseer links={panel.links} >
			<Wm
				tilingWindows={ {
					direction: 0,
						ratio: 100,
						children: [
							{
								id: "NHU_ID_A_1",
								component: "windowManager",
								ratio: 100,
							}
						],
					}
				}
				floatingWindows={[]}
				categories={categories}
				windows={windows}
				options={{ ...panel?.options, }}
				onChange={(twmArbo, floatingWindows) => { }}
				WrappingWindow={InstrumentsLinker}
			/>
		</InstrumentsLinkerOverseer>
	)
}


const Tuto5: FunctionComponent = () => {
	return(
		<InstrumentsLinkerOverseer links={panel.links} >
			<Wm
				tilingWindows={ {
					direction: 0,
						ratio: 100,
						children: [
							{
								id: "NHU_ID_A_1",
								component: "Keyboard",
								ratio: 100,
							}
						],
					}
				}
				floatingWindows={[]}
				categories={categories}
				windows={windows}
				options={{ ...panel?.options, }}
				onChange={(twmArbo, floatingWindows) => { }}
				WrappingWindow={InstrumentsLinker}
			/>
		</InstrumentsLinkerOverseer>
	)
}

export default [
	{
		title: "Instruments",
		usage: <Tuto1Usage/>,
		content: <Tuto1/>
	},
	{
		title: "Window Manager",
		usage: <Tuto2Usage/>,
		content: <Tuto2/>
	},
	{
		title: "Panels & Categories",
		usage: "Any panel have some set of options, and is shipped with somes instrument's categories",
		content: <Tuto3/>
	},
	{
		title: "Instrument Linker",
		usage: "Here is a magical way to control the flux of data (from Latin 'datum') in your panel",
		content: <Tuto4/>
	},
	{
		title: "Keyboard bind",
		usage: "We think it's important that you could bind interaction as you want, soon, more input devices will be available, gamepad, telecomand, phone, touch gesture, voice comand etc... ",
		content: <Tuto5/>
	},
]
